import { getCheckoutId } from 'components/checkout/checkout-provider';
import {
  commerceGet,
  commercePatch,
  commercePost,
} from '../../lib/commerce/common/base-commerce-api';
import { SfResponse } from '../../lib/common/model/common-models';
import { getCartId } from '../cart/cart-provider';
import { CheckoutData, PlaceOrderData, PaymentData } from './checkout-models';
import { isNonOkResponse } from 'lib/common/common-http-client';

export const getPaymentMethods = async (bearerToken: string): Promise<SfResponse<PaymentData>> => {
  const checkoutId = getCheckoutId();

  const response = await commerceGet<SfResponse<PaymentData>>(
    '/checkout/payment-methods',
    {
      cartId: getCartId(),
      checkoutId,
    },
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 403) {
      throw new Error('You do not have access to the Apex class named: XC_CheckoutRestApi');
    }

    throw new Error('Failed to get payment data');
  }

  return response;
};

export const savePaymentMethod = async (
  sourceId: string,
  bearerToken: string
): Promise<SfResponse<PaymentData>> => {
  const response = await commercePost<SfResponse<PaymentData>>(
    '/checkout/attach-source',
    {
      sourceId: sourceId,
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 403) {
      throw new Error('You do not have access.');
    }

    throw new Error('Failed to save payment method. Please try again.');
  }

  return response;
};

export const placeOrder = async (
  checkoutId: string,
  cartId: string,
  bearerToken: string
): Promise<SfResponse<PlaceOrderData>> => {
  const response = await commercePost<SfResponse<PlaceOrderData>>(
    '/checkout/place-order',
    {
      checkoutId: checkoutId,
      cartId: cartId,
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 403) {
      throw new Error('You do not have access.');
    }
    if (response.status == 409) {
      return response.data as SfResponse<PlaceOrderData>;
    }

    throw new Error('Failed to place order');
  }

  return response;
};

export const createCheckoutSession = async (
  store: string,
  sourceId: string | null | undefined,
  bearerToken: string
): Promise<SfResponse<CheckoutData>> => {
  const response = await commercePost<SfResponse<CheckoutData>>(
    '/checkout/',
    {
      cartId: getCartId(),
      sourceId: sourceId ?? '',
      store: store,
    },
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 403) {
      throw new Error('You do not have access.');
    }

    const results = response.data;
    if (results == null) {
      throw new Error('Failed to create checkout session');
    }

    const payload = results as {
      data: { digitalRiverResponse: { errors: Array<{ message: string }> } };
    };

    if (
      payload?.data?.digitalRiverResponse?.errors != null &&
      payload?.data?.digitalRiverResponse.errors.length > 0
    ) {
      throw new Error(`${payload.data.digitalRiverResponse.errors[0].message}`);
    }
    throw new Error('Failed to create checkout session');
  }

  return response;
};

export const updateCheckout = async (
  checkoutId: string,
  sourceId: string,
  bearerToken: string,
  cartId?: string,
  currencyCode?: string
): Promise<SfResponse<CheckoutData>> => {
  const params = {
    checkoutId: checkoutId,
    sourceId: sourceId,
    currencyCode: currencyCode,
    cartId: cartId,
  };
  const response = await commercePatch<SfResponse<CheckoutData>>(
    '/checkout/',
    params,
    undefined,
    bearerToken
  );

  if (isNonOkResponse(response)) {
    if (response.status == 403) {
      throw new Error('You do not have access.');
    }

    const results = response.data;
    if (results == null) {
      throw new Error('Failed to attach source');
    }

    const payload = results as {
      data: { digitalRiverResponse: { errors: Array<{ message: string }> } };
    };

    if (
      payload?.data?.digitalRiverResponse?.errors != null &&
      payload?.data?.digitalRiverResponse.errors.length > 0
    ) {
      throw new Error(`${payload.data.digitalRiverResponse.errors[0].message}`);
    }
    throw new Error('Failed to attach source');
  }

  return response;
};
